/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-ui@1.12.1/ui/version.min.js
 * - /npm/jquery-ui@1.12.1/ui/widget.min.js
 * - /npm/jquery-ui@1.12.1/ui/data.min.js
 * - /npm/jquery-ui@1.12.1/ui/scroll-parent.min.js
 * - /npm/jquery-ui@1.12.1/ui/widgets/mouse.min.js
 * - /npm/jquery-ui@1.12.1/ui/widgets/sortable.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
